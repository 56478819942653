import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import userManager from "./utils/authService";
import LoadingModal from "./LoadingModal";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const [user, setUser] = useState(null);
  const [, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    userManager
      .getUser()
      .then((usr) => {
        setUser(usr);
        if (usr) {
          const image = location.state?.image;
          if (image?.src) {
            axios
              .get(image.src, { responseType: "blob" }) // Set responseType to 'blob'
              .then((response) => {
                const fileObject = new File([response.data], "image.jpg", { type: "image/jpeg" });
                setFile(fileObject);
                uploadFile(fileObject, usr.access_token);
              })
              .catch((error) => {
                console.error("Error reading image:", error);
                // Handle the error here
              });
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, userManager]);

  const handleImagesButtonClick = () => {
    navigate("/images");
  };

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      uploadFile(selectedFile);
    }
  };

  const uploadFile = async (file, token) => {
    if (!file) return;
    setResult(null);
    setError(null);
    setMessage("Identifying animal...");
    setLoading(true);
    const formData = new FormData();
    formData.append("media", file);
    formData.append("species", "cattle");
    formData.append("organisationId", "ff5cabd5-7669-47a4-ae41-6d2acf98c276");
    formData.append("threshold", 0.4);
    try {
      const response = await axios.post("https://stoktake-production-client-aggregator-au-1.azurewebsites.net/animals/identify", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token ?? user.access_token}`,
        },
      });
      if (response.data?.data?.[0].id) {
        const animalId = response.data?.data?.[0].id;
        const animalInfo = await getAnimalInfo(animalId, token ?? user.access_token);
        setResult({
          ...response.data.data?.[0],
          ...animalInfo,
        });
      } else {
        setResult(response.data);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setError("Failed to upload image. Please try again.");
    } finally {
      setLoading(false);
      navigate(location.pathname, { replace: true });
    }
  };

  const getAnimalInfo = async (animalId, token) => {
    try {
      const response = await axios.get(`https://stoktake-production-client-aggregator-au-1.azurewebsites.net/animals/${animalId}`, {
        headers: {
          Authorization: `Bearer ${token ?? user.access_token}`,
        },
      });
      return response.data?.data;
    } catch (error) {
      console.error("Error fetching animal info:", error);
      return null;
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCameraButtonClick = () => {
    cameraInputRef.current.click();
  };

  const resetUpload = () => {
    setFile(null);
    setResult(null);
    setError(null);
  };

  const login = () => {
    setMessage("Logging in...");
    setLoading(true);
    userManager.signinRedirect({
      extraQueryParams: {
        "autologin:username": "Beef24",
        "autoLogin:password": "Beef24!",
      },
    });
  };

  const logout = () => {
    userManager.signoutRedirect();
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <nav className="flex justify-between items-center p-5 bg-gray-100">
        <a href="/">
          <img src="/long_logo.png" alt="Logo" style={{ maxWidth: "300px", width: "60vw", height: "auto" }} />
        </a>
        {user && (
          <button onClick={logout} className="py-2 px-4 bg-[#EC6C3F] text-white rounded hover:bg-[#bd5833]">
            Logout
          </button>
        )}
      </nav>
      <main className="flex-grow flex items-center justify-center">
        <LoadingModal show={loading} message={message} />
        {user ? (
          <div className="text-center w-full max-w-lg">
            <h1 className="text-xl font-semibold text-gray-800">Identification Demo</h1>
            <div className="mt-8">
              {!result && !error && (
                <div>
                  <p className="text-gray-600 font-semibold mt-2 mx-3">Please upload or take a photo of a pre-registered cow to identify it. You can also just use one of our preloaded images.</p>
                  <input type="file" ref={fileInputRef} onChange={onFileChange} accept="image/*" className="hidden" />
                  <input type="file" ref={cameraInputRef} onChange={onFileChange} accept="image/*" capture="environment" className="hidden" />

                  <div className="flex space-x-4 my-3 px-6 items-stretch justify-center">
                    <button type="button" onClick={handleFileButtonClick} className="flex flex-col items-center justify-center font-semibold w-1/3 py-3 px-1 bg-[#4BA163] text-white rounded">
                      <i className="fa fa-upload fa-2xl my-3 pb-2  leading-4 fa-align-justify"></i>
                      <span>
                        Upload
                        <br />
                        Image
                      </span>
                    </button>
                    <button type="button" onClick={handleCameraButtonClick} className="flex flex-col items-center justify-center font-semibold w-1/3 py-3 px-1 bg-[#bd5833] text-white rounded">
                      <i className="fa fa-camera fa-2xl my-3 pb-2  leading-4 fa-align-justify"></i>
                      <span>
                        Take
                        <br />
                        Photo
                      </span>
                    </button>
                    <button type="button" onClick={handleImagesButtonClick} className="flex flex-col items-center justify-center font-semibold w-1/3 py-3 px-1 bg-[#745B49] text-white rounded">
                      <i className="fa fa-images fa-2xl my-3 pb-2 leading-4  fa-align-justify"></i>
                      <span>
                        Preloaded
                        <br />
                        Images
                      </span>
                    </button>
                  </div>
                  <div>
                    <p className="text-gray-600 mt-2 mx-3 text-sm mb-3">Supported formats: jpg, jpeg, png</p>
                  </div>
                </div>
              )}
            </div>
            {result && (
              <div className="flex flex-col mt-6 p-6 bg-white rounded-lg shadow-xl max-w-2xl mx-auto">
                <h3 className="text-xl font-bold text-gray-800 mb-4">Results</h3>
                <img src={result.profileMedia?.optimised?.medium?.linkUrl ?? result.profileMedia?.linkUrl} alt="Animal" className="mx-auto w-auto max-h-60 mt-2 shadow-sm" />
                <div className="mt-4 ">
                  <p className="text-lg text-gray-900 font-semibold">
                    Name: <span className="font-normal">{result.name}</span>
                  </p>
                  <p className="text-lg text-gray-900 font-semibold">
                    Breed: <span className="font-normal">{result.breed}</span>
                  </p>
                  <p className="text-lg text-gray-900 font-semibold">
                    Gender: <span className="font-normal">{result.metadata?.gender?.value}</span>
                  </p>
                  <p className="text-lg text-gray-900 font-semibold">
                    Confidence: <span className="font-normal">{result.prediction}%</span>
                  </p>
                </div>
                <a href="https://au.bigin.online/org7003931992/forms/keep-in-touch" target="_blank" rel="noreferrer" className="mt-4 py-3 px-6 text-lg bg-[#4BA163] text-white rounded hover:bg-[#4BA163]">
                  <i className="fa fa-hand-pointer fa-lg mr-2"></i> Keep in touch with us
                </a>
                <button onClick={resetUpload} className="mt-4 py-3 px-6 bg-[#EC6C3F] text-white rounded hover:bg-[#bd5833]">
                  Perform another search
                </button>
              </div>
            )}
            {error && (
              <div className="mt-4 p-4 bg-white rounded-lg shadow-lg max-w-md mx-auto">
                <p className="text-red-500">{error}</p>
                <button onClick={resetUpload} className="mt-4 py-3 px-6 bg-[#EC6C3F] text-white rounded hover:bg-[#bd5833]">
                  Try again
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center mt-10">
            <h1 className="text-4xl font-semibold text-gray-800 mb-8">Welcome to the Stoktake Demo</h1>
            <div className="flex items-center justify-center min-w-screen">
              <img src="/logo.png" alt="Stoktake Logo" style={{ maxWidth: "350px", width: "60vw", height: "auto" }} />
            </div>
            <button onClick={login} className="py-3 px-6 mt-8 bg-[#4BA163] text-white text-lg rounded mb-4">
              <i className="fa fa-hand-pointer fa-lg mr-2"></i> Click here to get started
            </button>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default App;
