import React, { useEffect } from "react";
import userManager from "./utils/authService";

function Login() {
  useEffect(() => {
    userManager
      .signinRedirectCallback()
      .then(() => {
        window.location = "/";
      })
      .catch(async (error) => {
        if (error.error === "login_required") {
          await userManager.signinRedirect({
            extraQueryParams: {
              "autologin:username": "Beef24",
              "autoLogin:password": "Beef24!",
            },
          });
          return;
        }
        console.error(error);
        window.location = "/";
      });
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h2 className="text-2xl font-semibold text-[#4BA163]">
          Logging you in...
        </h2>
        <p className="text-gray-500 mt-2">Please wait.</p>
      </div>
    </div>
  );
}

export default Login;
