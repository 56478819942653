import React, { useEffect, useState } from "react";
import userManager from "./utils/authService";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const images = [
  { src: "/cattle/image-8.jpg", title: "Image 1" },
  { src: "/cattle/image-7.jpg", title: "Image 2" },
  { src: "/cattle/image-1.jpg", title: "Image 3" },
  { src: "/cattle/image-2.jpg", title: "Image 4" },
  { src: "/cattle/image-3.jpg", title: "Image 5" },
  { src: "/cattle/image-4.jpg", title: "Image 6" },
  { src: "/cattle/image-5.jpg", title: "Image 7" },
  { src: "/cattle/image-6.jpg", title: "Image 8" },
  { src: "/cattle/image-9.jpg", title: "Image 9" },
  { src: "/cattle/image-10.jpg", title: "Image 10" },
];

function ImageGallery() {
  const [showLightbox, setShowLightbox] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    userManager
      .getUser()
      .then((user) => {
        if (!user) navigate("/");
      })
      .catch(() => navigate("/"));
  }, [navigate]);

  const handleHomeButtonClick = () => {
    navigate("/");
  };

  const openLightbox = (image) => {
    setCurrentImage(image);
    setShowLightbox(true);
  };

  const closeLightbox = () => {
    setShowLightbox(false);
    setCurrentImage(null);
  };

  const logout = () => {
    userManager.signoutRedirect();
  };

  const uploadImage = (image) => {
    navigate("/", { state: { image } });
    return false;
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <nav className="flex justify-between items-center p-5 bg-gray-100">
        <a href="/">
          <img src="/long_logo.png" alt="Logo" style={{ maxWidth: "300px", width: "60vw", height: "auto" }} />
        </a>
        <button onClick={logout} className="py-2 px-4 bg-[#EC6C3F] text-white rounded hover:bg-[#bd5833]">
          Logout
        </button>
      </nav>
      <main className="flex flex-grow items-center justify-center">
        <div className="flex flex-col items-center pt-5 pb-5">
          <button onClick={handleHomeButtonClick} className="py-2 px-4 bg-[#4BA163] text-white rounded hover:bg-[#bd5833]">
            Back to Home
          </button>
          <div className="w-full flex flex-col items-center p-5">
            <h1 className="text-2xl font-bold text-gray-800 mb-3">Demo Image Gallery</h1>
            <p className="text-md text-gray-600 mb-5 text-left">
              <strong>Instructions:</strong>
              <ul className="pl-3">
                <li className="my-2">
                  <strong>View Images:</strong> Tap on any image to view it in a larger format.
                </li>
                <li className="mb-2">
                  <strong>Download and Upload Images:</strong> Press the "Download" button to save the image. After downloading, you can manually upload it as needed.
                </li>
                <li>
                  <strong>Identification:</strong> Simply press the "Identify" button to use the image for identification purposes.
                </li>
              </ul>
            </p>
          </div>
          <div className="flex flex-wrap justify-center gap-4 px-1">
            {images.map((image, index) => (
              <div key={index} className="md:w-1/4 sm:w-1/3 max-w-[45%]">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                  <div className="max-h-[200px]">
                    <img src={image.src} alt={image.title} className="cursor-pointer object-cover w-full" onClick={() => openLightbox(image)} />
                  </div>
                  <div className="flex justify-center items-center pt-10">
                    <a href={image.src} download className="bg-[#4BA163] text-lg w-full text-center text-white font-bold py-2 px-2 rounded-t">
                      Download
                    </a>
                  </div>
                  <div className="flex justify-center items-center">
                    <button type="button" onClick={() => uploadImage(image)} className="bg-[#bd5833] text-lg w-full text-center text-white font-bold py-2 px-2 rounded-b">
                      Identify
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {showLightbox && (
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center cursor-pointer" onClick={closeLightbox}>
                <img src={currentImage.src} alt={currentImage.title} className="max-w-4/5 max-h-4/5" />
                <p className="absolute bottom-10 text-white text-lg">Click anywhere to close</p>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default ImageGallery;
