import React from "react";

const LoadingModal = ({ show, message }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-lg font-bold mb-4">{message ?? "Identifying..."}</h3>
        <div className="spinner" style={{ margin: "0 auto" }}></div> {/* Using the custom spinner */}
      </div>
    </div>
  );
};

export default LoadingModal;
