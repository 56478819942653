import React from "react";

const Footer = () => {
  return (
    <footer className="mt-3 w-full text-center pb-5 flex flex-col items-center bg-gray-200 p-4 shadow md:flex md:items-center md:justify-between md:p-6">
      <a href="https://au.bigin.online/org7003931992/forms/keep-in-touch" target="_blank" rel="noreferrer" className="text-xl text-[#4BA163] hover:text-[#326c43] font-semibold mb-3">
        Connect with Us
      </a>
      <div className="flex space-x-4 mb-3">
        <a href="https://au.bigin.online/org7003931992/forms/keep-in-touch" target="_blank" rel="noreferrer" className="text-[#EC6C3F]">
          <i className="fa fa-envelope fa-2x"></i>
        </a>
        <a href="https://www.stoktake.com" target="_blank" rel="noreferrer" className="text-[#EC6C3F]">
          <i className="fa fa-globe fa-2x"></i>
        </a>
        <a href="https://www.linkedin.com/company/stoktake" target="_blank" rel="noreferrer" className="text-[#EC6C3F]">
          <i className="fab fa-linkedin fa-2x"></i>
        </a>
        <a href="https://www.facebook.com/stoktake" target="_blank" rel="noreferrer" className="text-[#EC6C3F]">
          <i className="fab fa-facebook fa-2x"></i>
        </a>
        <a href="https://www.instagram.com/stoktake" target="_blank" rel="noreferrer" className="text-[#EC6C3F]">
          <i className="fab fa-instagram fa-2x"></i>
        </a>
      </div>
      <hr className="w-[80vw] mb-3 border-gray-500 border-t-2" />
      <small className="w-[80vw] text-xs">Please note that the demonstration is for illustrative purposes only and may not be representative of the actual product. This demonstration is based on a predefined set of animals. Get demonstration images using the provided link or see the team at the expo for photo cards.</small>
      <small className="w-[80vw] mt-3  text-xs">
        Powered by{" "}
        <a className="text-[#4BA163]" href="https://stoktake.com" target="_blank" rel="noreferrer">
          Stoktake
        </a>
        . &copy; Stoktake 2024
      </small>
    </footer>
  );
};

export default Footer;
