import { UserManager, WebStorageStateStore } from "oidc-client";

const config = {
  authority: "https://identity.stoktake.app", // URL of your IdentityServer
  client_id: "stoktake.demoapp",
  redirect_uri: "https://demo.stoktake.app/login",
  response_type: "code",
  scope: "client_access openid",
  post_logout_redirect_uri: "https://demo.stoktake.app/",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const userManager = new UserManager(config);

export default userManager;
